import { atom } from "nanostores";

// TODO: rename to isExpanded
export const railExpandedStore = atom<boolean>(true);

// TODO: rename to selectNavItem
export const selectedNavStore = atom<string>("");

export const toggleRail = () => {
	railExpandedStore.set(!railExpandedStore.get());
};
export const selectNavItem = (navItem: string) => selectedNavStore.set(navItem);
