import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyIcon from "@mui/icons-material/Key";
import ListAltIcon from "@mui/icons-material/ListAlt";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { Avatar, Icon, Tooltip } from "@mui/material";
import { useStore } from "@nanostores/react";
import HelpIcon from "common/components/Icons/HelpIcon";
import {
	railExpandedStore,
	selectedNavStore,
	selectNavItem,
	toggleRail,
} from "common/stores/NavigationRailStore";
import { user } from "common/stores/UserStore";
import Link from "next/link";
import { useEffect } from "react";
import FileChartIcon from "../Icons/FileChartIcon";
import TruckIcon from "../Icons/TruckIcon";
import styles from "./leftRail.module.css";
// import FileChartIcon from "../Icons/FileChartIcon";
export interface NavItem {
	id: number;
	label: string;
	path: string;
	icon?: React.ReactElement<typeof Icon>;
}

export interface LeftRailProps {
	items?: Array<NavItem>;
	bottomItems?: Array<NavItem>;
	selected?: string;
	initialExpanded?: boolean;
	railExpanded?: boolean;
	handleExpanderClick?: () => void;
	hideBaseNavItems?: boolean;
}

/**
 * A side rail navigation for optera themed pages.
 */
function LeftRail({
	items = [],
	bottomItems = [],
	hideBaseNavItems = false,
	initialExpanded,
}: LeftRailProps) {
	useEffect(() => {
		if (initialExpanded === false) {
			railExpandedStore.set(initialExpanded);
		}
	}, [initialExpanded]);
	const railExpanded = useStore(railExpandedStore);
	const selectedNav = useStore(selectedNavStore);
	const $user = useStore(user);
	const isAdmin = $user.roles.includes("write:organization");
	const surveyAdminAccess = $user.roles.includes("survey:surveyor");
	// const surveyAdminAccess = $user.endorsements.includes("survey:surveyor"); // NOTE: not using endorsement because of quirk of Survey Co setup
	const apiKeyFeatureAccess = $user.feature_flags.includes("api-keys");
	const surveyAccess = Object.keys($user.traits).includes("survey:surveyee");
	const scmv2Access = $user.endorsements.includes(
		"supply_chain_insights:brand"
	);

	const baseNavItems: NavItem[] = [];

	if (isAdmin) {
		bottomItems = [
			{
				id: 2,
				label: "User Management",
				path: "/admin/users",
				icon: <PeopleOutlineIcon />,
			},
			{
				id: 3,
				label: "Survey User Management",
				path: "/admin/survey",
				icon: <PeopleOutlineIcon />,
			},
			...bottomItems,
		];
	}
	if (scmv2Access) {
		baseNavItems.push({
			id: 7,
			label: "Supply Chain",
			path: "/supplyChainManager",
			icon: <TruckIcon />,
		});
		//comment out for now :)
		baseNavItems.push({
			id: 9,
			label: "Reports",
			path: "/reports",
			icon: <FileChartIcon />,
		});
		bottomItems.push({
			id: 8,
			label: "Help",
			path: "/facade/auth/owl-docs?r=/docs/getting-started-with-supply-chain-manager",
			icon: <HelpIcon color="var(--oc-palette-secondary-400)" />,
		});
	}
	if (surveyAccess) {
		baseNavItems.push({
			id: 4,
			label: "Survey",
			path: "/survey/campaign-select",
			icon: <ListAltIcon />,
		});
	}
	if (surveyAdminAccess) {
		baseNavItems.push({
			id: 5,
			label: "Survey Admin",
			path: "/survey/surveyProgressDashboard",
			icon: <ListAltIcon />,
		});
	}
	if (apiKeyFeatureAccess) {
		baseNavItems.push({
			id: 6,
			label: "API Key Management",
			path: "/admin/api-keys",
			icon: <KeyIcon />,
		});
	}

	let navItems = hideBaseNavItems == true ? items : [...baseNavItems, ...items];
	return (
		<nav id="leftRail-nav" className={styles.nav} aria-label="Side navigation">
			<Tooltip
				arrow={true}
				placement="right"
				title={railExpanded ? "Collapse" : "Expand"}
			>
				<button
					type="button"
					className={styles.toggle}
					onClick={toggleRail}
					aria-expanded={railExpanded ? "true" : "false"}
					aria-controls="leftRail-nav"
					aria-label="widen or narrow left side nav"
				>
					{railExpanded ? (
						<ChevronLeftIcon style={{ fontSize: 20 }} />
					) : (
						<ChevronRightIcon style={{ fontSize: 20 }} />
					)}
				</button>
			</Tooltip>
			<div className={styles.marginTransition}>
				<div className={railExpanded ? "" : styles.avatarMarginClosed}>
					{railExpanded
						? $user.organization && (
								<div className={styles.currentOrg}>
									<Avatar variant="rounded" className={styles.companyAvatar}>
										{$user.organization[0]}
									</Avatar>
									<p className={`body2 ${styles.currentOrgName}`}>
										{$user.organization}
									</p>
								</div>
							)
						: $user.organization && (
								<Tooltip
									arrow={true}
									placement="right"
									title={$user.organization}
									className={styles.headerCollapsed}
								>
									<Avatar variant="rounded" className={styles.companyAvatar}>
										{$user.organization[0]}
									</Avatar>
								</Tooltip>
							)}
				</div>
				<div>
					{railExpanded ? (
						<ul aria-label="Side navigation menu" role="menubar">
							{navItems?.map((item: NavItem, i: number) => (
								<li
									key={i}
									className={item.label === selectedNav ? styles.selected : ""}
									onClick={() => selectNavItem(item.label)}
									role="none"
								>
									<Link href={item.path} tabIndex={-1}>
										<Avatar
											variant="square"
											sx={{
												color: "text.secondary",
												height: 36,
												width: 36,
											}}
										>
											{item.icon}
										</Avatar>
									</Link>
									<Link
										href={item.path}
										className={styles.navLink}
										role="menuitem"
										tabIndex={0}
									>
										{item.label}
									</Link>
								</li>
							))}
						</ul>
					) : (
						<ul aria-label="Side navigation menu" role="menubar">
							{navItems?.map((item: NavItem, i: number) => (
								<Tooltip
									key={i}
									arrow={true}
									placement="right"
									title={item.label}
								>
									<li
										className={item.label == selectedNav ? styles.selected : ""}
										onClick={() => selectNavItem(item.label)}
										role="none"
									>
										<Link href={item.path} role="menuitem" tabIndex={0}>
											<Avatar
												variant="square"
												sx={{
													color: "text.secondary",
													height: 36,
													width: 36,
												}}
											>
												{item.icon}
											</Avatar>
										</Link>
									</li>
								</Tooltip>
							))}
						</ul>
					)}
				</div>
				<div className={styles.footer}>
					{railExpanded ? (
						<ul aria-label="Side navigation lower menu" role="menubar">
							{bottomItems?.map((item: NavItem, i: number) => (
								<li key={i}>
									<Link
										href={item.path}
										role="menuitem"
										aria-label={`Route to ${item.path}`}
										tabIndex={-1}
									>
										<Avatar
											variant="square"
											sx={{
												color: "text.secondary",
												height: 36,
												width: 36,
											}}
										>
											{item.icon}
										</Avatar>
									</Link>
									<Link
										tabIndex={0}
										href={item.path}
										className={styles.navLink}
									>
										{item.label}
									</Link>
								</li>
							))}
						</ul>
					) : (
						<ul aria-label="Side navigation lower menu" role="menubar">
							{bottomItems?.map((item: NavItem, i: number) => (
								<Tooltip
									key={i}
									arrow={true}
									placement="right"
									title={item.label}
								>
									<li key={i} role="none">
										<Link href={item.path} role="menuitem" tabIndex={0}>
											<Avatar
												variant="square"
												sx={{
													color: "text.secondary",
													height: 36,
													width: 36,
												}}
											>
												{item.icon}
											</Avatar>
										</Link>
									</li>
								</Tooltip>
							))}
						</ul>
					)}
				</div>
			</div>
		</nav>
	);
}

export default LeftRail;
